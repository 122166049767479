// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-detalii-proiect-js": () => import("./../../../src/pages/detaliiProiect.js" /* webpackChunkName: "component---src-pages-detalii-proiect-js" */),
  "component---src-pages-echipa-js": () => import("./../../../src/pages/echipa.js" /* webpackChunkName: "component---src-pages-echipa-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-proiecte-js": () => import("./../../../src/pages/proiecte.js" /* webpackChunkName: "component---src-pages-proiecte-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

